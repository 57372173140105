<!--
 * @Author: 张博洋
 * @Date: 2021-07-26 14:19:19
 * @LastEditTime: 2022-02-25 19:56:04
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/consult/edit.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="consult">
    <notice-bar :noticeIconColor="noticeOption.iconColor" :noticeIcon="noticeOption.icon" :noticeBackground="noticeOption.background">
      <span class="str">{{noticeOption.str}}</span>
    </notice-bar>

    <div class="center mt8 ml8 mr8">
      <van-form class="form" ref='form' @submit="submitAction">
        <div class="p">
          <van-field v-model="patientName" name="就诊人" label="就诊人" right-icon="arrow" input-align="right" clickable readonly required placeholder="请选择" :rules="[$.rules.required]" clearable
            @click="selectPatient">

          </van-field>
          <van-field v-model="params.patientPhone" type='tel' name="手机号码" input-align="right" maxlength="11" required label="手机号码" placeholder="请填写患者手机号"
            :rules="[$.rules.required,$.rules.oldTel(params.patientPhone)]" @blur="fieldBlur('patientPhone')" clearable />
        </div>
        <div class="p mt8">
          <van-field class="des" name="病情描述" required autosize label="病情描述" input-align="right" maxlength="200" show-word-limit type="textarea" placeholder="请输入" v-model="params.diseaseDesc"
            :rules="[$.rules.required]" @blur="fieldBlur('diseaseDesc')" />
        </div>

        <div class="p mt8">
          <van-field name="上传图片" required class="">
            <template #label>上传图片<span class="slot">（病历或检查图片最多9张）</span></template>
          </van-field>
          <div class="upload-con mt8 ml16">
            <image-upload v-model="fileList" ref="upload" action="/patient-api/patient/common/upload"></image-upload>
          </div>

        </div>

        <div class="p mt8 price">
          <van-field label="咨询金额" required :value="`¥${params.orderAmount}`" readonly />
        </div>
      </van-form>
    </div>
    <div class="bottom-bar">
      <Service @checkboxEvent="handleCheckbox"></Service>
      <div class="btn-group">
        <div class="price">实付款 <span>¥</span><span class="num">{{params.orderAmount}}</span></div>
        <van-button class="submit" @click="submitAction">提交订单</van-button>
      </div>
    </div>

    <van-popup class="popup" v-model="agreementShow" :close-on-click-overlay="false" position="bottom" :style="{ height: '444px' }">
      <div class="agreement-content">
        <h1>{{title}}</h1>
        <div v-html="content" class="editContent"></div>
      </div>

      <div class="button">
        <van-button type="primary" :disabled="disable" @click="toOrderDetail">
          <van-count-down ref="countDown" :time="countDown" @finish="countDownFinish" class="mr10" v-if="disable">
            <template #default="timeData">
              <span class="block">{{ timeData.seconds }}S</span>
            </template>
          </van-count-down>
          {{btnText}}
        </van-button>
      </div>
    </van-popup>

    <PatientListPanel :list="list" :patientIsShow="patientIsShow" @closePatient="closePatient" @closePanel="closePanel"></PatientListPanel>
  </div>

</template>

<script>
import NoticeBar from '@/components/noticeBar'
import Service from './components/service.vue'
import forms from '@/mixin/Form.js'
import button from '@/mixin/Button.js'
import imageUpload from '@/components/upload/imageUpload'
import PatientListPanel from '@/components/patient/patientListPanel.vue'
export default {
  mixins: [forms, button],
  data() {
    return {
      content: '',
      title: '',
      agreementShow: false,
      noticeOption: {
        iconColor: '#ED6A0C',
        icon: 'info-o',
        background: '#FFFBE8',
        str: '急重症患者不适合网上咨询/问诊，请前往医院就诊',
      },
      params: {
        patientId: '',
        patientPhone: '',
        diseaseDesc: '',
        imageUrls: '',
        orderAmount: '',
        doctorAccountId: '',
      },
      patientName: '',
      pe: '',
      allergyHistory: '',
      fileList: [],
      name: this.$route.params.name || '',
      checkbox: false,
      btnText: '我已阅读并同意',
      countDown: 10 * 1000,
      disable: true,
      list: [],
      patientIsShow: false,
    }
  },
  components: {
    NoticeBar,
    imageUpload,
    Service,
    PatientListPanel,
  },

  created() {
    console.log(this.patientIsShow)
    this.getPatientList()
    // alert(this.$route.query.patientAccountId)
    // alert(typeof (this.$route.query.type))
    if (this.$route.query.doctorAccountId) {
      this.params.doctorAccountId = this.$route.query.doctorAccountId
      localStorage.setItem('doctorAccountId', this.$route.query.doctorAccountId)
    } else {
      this.params.doctorAccountId =
        this.$route.query.doctorAccountId ||
        localStorage.getItem('doctorAccountId')
    }

    if (this.$route.query.doctorProductId) {
      this.params.doctorProductId = this.$route.query.doctorProductId
      localStorage.setItem('doctorProductId', this.$route.query.doctorProductId)
    } else {
      this.params.doctorProductId =
        this.$route.query.doctorProductId ||
        localStorage.getItem('doctorProductId')
    }

    if (this.$route.query.price) {
      this.params.orderAmount = this.$route.query.price
      localStorage.setItem('price', this.$route.query.price)
    } else {
      this.params.orderAmount =
        this.$route.query.price || localStorage.getItem('price')
    }

    this.$.setTitle(
      this.$route.query.type === '1' ? '填写咨询单' : '填写问诊单'
    )
  },
  activated() {
    console.log(this.$route.name)
    this.getPatientList()
  },
  methods: {
    // 表单失焦清空只有空格的内容
    fieldBlur(type) {
      if (this.params[type].trim().length <= 0) {
        this[type] = ''
      }
    },
    getPatientList() {
      this.$axios({
        url: 'healthy/getPatients',
        _self: this,
      }).then((res) => {
        console.log(res)
        this.list = [...res.d.data]
      })
    },

    closePatient(payload) {
      this.params.patientId = payload.patientId
      this.params.patientPhone = payload.phone
      this.patientName = payload.patientName
      console.log('payload', payload)
      this.patientIsShow = false
      
    },
    closePanel() {
       this.patientIsShow = false
    },
    selectPatient(){
        this.patientIsShow = true
    },
    handleCheckbox(payload) {
      console.log(payload)
      this.checkbox = payload
    },
    countDownFinish() {
      console.log('121212121')
      // this.$refs.countDown.reset();
      // this.$refs.countDown.finish();
      this.disable = false
    },
    getAgreement(type) {
      this.$axios({
        type: 'post',
        url: 'common/getContract',
        data: {
          type: type,
        },
        elseData: {
          loading: true,
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.content = res.d.content
        this.title = res.d.title
      })
    },
    submitAction() {
      this.appCheckFormAction().then(() => {
        console.log(this.fileList)
        if (this.fileList.length === 0) {
          this.$.toast.warning('请上传病历或检查图片')
          return
        } else if (this.fileList.length === 1) {
          this.params.imageUrls = this.fileList[0].url
        } else {
          let arr = []
          this.fileList.forEach((item) => {
            arr.push(item.url)
          })
          this.params.imageUrls = arr.join(',')
        }
        console.log(this.params)
        if (!this.checkbox) {
          this.$.toast.warning('请先同意《用户协议》与《知情同意书》')
          return
        } else {
          // 打开协议弹窗
          this.getAgreement('300004')
          this.agreementShow = true
        }
      })
    },
    toOrderDetail() {
      console.log(this.params)
      this.$axios({
        type: 'post',
        url: 'order/create',
        data: this.params,
        elseData: {
          loading: true,
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        if (res.data.code === 0 && res.data.msg === '成功') {
          // alert()
          this.$router.replace({
            name: 'order.detail',
            query: {
              orderId: res.d.id,
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.consult {
  height: 100%;
  overflow: hidden;
  .str {
    color: #323233;
    font-size: 13px;
  }
  .p {
    padding-top: 4px;
    border-radius: 8px;
    background: #fff;
    .van-cell {
      background: none;
    }
    /deep/.van-field__label {
      margin-left: 16px;
      width: fit-content;
      .slot {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #969799;
        line-height: 16px;
      }
    }
    /deep/.van-cell--required::before {
      left: initial;
    }

    .des {
      display: flex;
      flex-direction: column;
      /deep/.van-cell__value {
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        margin-top: 13px;
        padding: 12px;
        textarea.van-field__control {
          text-align: left;
        }
      }
    }

    .upload-con {
      padding-bottom: 16px;
    }
  }
  .p.price {
    /deep/.van-field__control {
      font-size: 16px;
      font-weight: 500;
      color: color(primarySub) !important;
      line-height: 23px;
      text-align: right;
    }
  }
  .van-cell::after {
    display: none;
  }

  .center {
    flex: 1;
    overflow-y: auto;
  }
  .bottom-bar {
    width: 100%;
    background: #fff;
    padding-top: 16px;
    margin-top: 8px;
    .agreement {
      // height: 48px;
    }
    .btn-group {
      width: 100%;
      display: flex;
      padding: 0 16px 30px 16px;
      .price {
        height: 44px;
        width: 71%;
        background: #333333;
        border-radius: 22px 0px 0px 22px;
        line-height: 44px;
        color: #fff;
        padding-left: 32px;
        display: flex;
        align-items: baseline;
        span.num {
          font-size: 20px;
          font-weight: 500;
          margin-left: 0px;
        }
        span {
          font-size: 14px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 13px;
        }
      }
      .submit {
        width: 29%;
        height: 44px;
        border-color: color(primarySub);
        background-color: color(primarySub) !important;
        border-radius: 0px 22px 22px 0px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        padding: 0;
      }
    }
  }
  .popup {
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    div.button {
      height: 84px;
      background: #ffffff;
      padding: 10px 20px 0;
      // margin: 0 20px;
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px #e5e5e5;
      .van-button--primary {
        width: 100%;
        height: 44px;
        border-radius: 22px;
        padding: 0 20px;
        .van-button__text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .van-count-down {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>