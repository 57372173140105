<!--
 * @Author: 张博洋
 * @Date: 2021-08-05 18:22:37
 * @LastEditTime: 2021-11-23 17:38:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/consult/components/service.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="agreement">
    <div class="ag">
      <van-checkbox v-model="checked"
                    checked-color="#1AB370"
                    shape="square"
                    @change="handleCheckbox"
                    icon-size="15px"
                    class="mr8"></van-checkbox>
      <div>我已阅读并知晓《<span @click="handleAgree('300001')">用户协议</span>》与《<span @click="handleAgree('300004')">知情同意书</span>》</div>
    </div>
  </div>
</template>

<script>
import checkbox from '@/mixin/Checkbox.js'
export default {
  mixins: [checkbox],
  data () {
    return {
      checked: '',
      src: '',
      showAgree: false
    }
  },
  methods: {
    handleCheckbox (val) {
      console.log(val)
      this.$emit('checkboxEvent', val)
    },
    handleAgree (type) {
      this.$router.push({
        name: 'agreement.agreement',
        query: {
          type: type
        }
      })
      // this.showAgree = true

    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';
.agreement {
  display: flex;
  align-items: flex-start;
  padding-left: 16px;
  margin-bottom: 25px;
}
.ag {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  & > div {
    span {
      font-weight: 400;
      color: color(primary);
    }
  }
}
</style>